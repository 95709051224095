@import '../resources/tire-review-card/scss/tire-review-card';
@import '../../../../../../../resources/scss/breakpoints';


// Custom
.cmp-carousel {
    height: 100%;
    position: relative;

    .media-gallery & {
        display: none;
    }
}


// Core
.swiper-container,
.swiper-container-thumbs {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    //z-index: 1;
}
.swiper-container-vertical > .swiper-wrapper {
    flex-direction: column;
}
.swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    //z-index: 1;
    display: flex;
    align-items: center;
    transition-property: transform;
    box-sizing: content-box;
}
.swiper-container-android .swiper-slide,
.swiper-wrapper {
    transform: translate3d(0px, 0, 0);
}
.swiper-container-multirow > .swiper-wrapper {
    flex-wrap: wrap;
}
.swiper-container-multirow-column > .swiper-wrapper {
    flex-wrap: wrap;
    flex-direction: column;
}
.swiper-container-free-mode > .swiper-wrapper {
    transition-timing-function: ease-out;
    margin: 0 auto;
}
.swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform;
}
.swiper-slide-invisible-blank {
    visibility: hidden;
}
/* Auto Height */
.swiper-container-autoheight {
  &,
  .swiper-slide {
      height: auto;
  }

  .swiper-wrapper {
      align-items: flex-start;
      transition-property: transform, height;
  }
}

/* CSS Mode */
.swiper-container-css-mode {
  > .swiper-wrapper {
      overflow: auto;
      scrollbar-width: none; /* For Firefox */
      -ms-overflow-style: none; /* For Internet Explorer and Edge */

      &::-webkit-scrollbar {
          display: none;
      }
  }
  > .swiper-wrapper > .swiper-slide {
      scroll-snap-align: start start;
  }
}
.swiper-container-horizontal.swiper-container-css-mode {
    > .swiper-wrapper {
      scroll-snap-type: x mandatory;
  }
}
.swiper-container-vertical.swiper-container-css-mode {
    > .swiper-wrapper {
      scroll-snap-type: y mandatory;
  }
}

/* Swiper Buttons */

/// this specificity of .swiper-container should not be needed
/// but it breaks stuff without it, NEEDS REVIEW
////.swiper-container {

    .swiper-button-next,
    .swiper-button-prev {
        display: none; // shown when .active
        width: 33px;
        height: 33px;
        border-radius: 50%;
        background-color: #fff;
        top: 40%;
        right: 30px;
        position: absolute;
        //z-index: 39;
        border: 1px solid #C6C6C6;
        box-shadow: 0 2px 4px 2px rgba(0,0,0,0.14);
        box-sizing: content-box;
        cursor: pointer;

        &:after {
            content: "";
                width: 2px;
                height: 2px;
                display: block;
                border: solid black;
                border-width: 0 2px 2px 0;
                padding: 3px;
                transform: rotate(-45deg);
                position: absolute;
                right: 13px;
                top: 12px;
        }

        &.active {
            display: flex;
        }
    }

    .swiper-button-prev {
        right: auto;
        left: 30px;

        &:after {
            right: 10px;
            transform: rotate(135deg);
        }
    }


////} // close .swiper-container


/* Scrollbar */
.swiper-scrollbar {
    border-radius: 10px;
    position: relative;
    -ms-touch-action: none;
    background-color: rgba(0, 0, 0, 0.1);

    .swiper-container-horizontal > & {
        position: absolute;
        left: 20%;
        right: 20%;
        bottom: 0;
        //z-index: 50;
        height: 5px;
        width: 60%;
    }

    .swiper-container-vertical > & {
        position: absolute;
        right: 3px;
        top: 1%;
        //z-index: 50;
        width: 5px;
        height: 98%;
    }
}

.swiper-scrollbar-drag {
    height: 100%;
    width: 100%;
    position: relative;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    left: 0;
    top: 0;
}

.swiper-scrollbar-cursor-drag {
    cursor: move;
}

.swiper-scrollbar-lock {
    display: none;
}




.swiper-container {
    width: 100%;
    height: auto;
    margin: 0 auto;
    cursor: grab;

    // these should not be burried in here
    .dynamic-top-content & {
        max-width: 900px;
        max-height: 470px;
    }

    // these should not be burried in here
    .content-card & {
        height: 210px;
    }

    >.swiper-wrapper {
        width: auto;
        margin: 0;
    }

}

.swiper-container-thumbs {

    .dynamic-top-content & {
        max-width: 50%;
        height: 150px;
        box-sizing: border-box;

        .swiper-wrapper {
            justify-content: center;
        }

        .swiper-slide {
            height: fit-content;
            box-sizing: border-box;
            border: 1px SOLID white;
            margin: 0 10px;

            img {
                display: block;
            }
        }

        .swiper-slide-thumb-active {
            opacity: 1;
            border: 1px SOLID black;
        }
    }
}


.swiper-slide {
    margin: 0;

    .content-card & {
        max-width: 400px;
    }

    > div {
        width: 100%;

        img {
            width: 100%;
        }
    }
}
