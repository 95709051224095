
// TODO: WHY IS THIS FILE HERE?????

.tire-review-card {

    .swiper-slide & {

        width: 400px;
        max-width: 95%;
        height: 190px;
        padding: 20px 35px;
        margin: auto;

    }

}